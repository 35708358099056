import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Alert,
  Table,
  Container,
  Badge,
} from "react-bootstrap";
import { get } from "../../utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import "./InvestorDashboard.scss";
import { UserContext } from "contexts/UserProvider";
import VoteCaster from "components/Dashboard/VentureProfile/VoteProfile/VoteCaster";
import { formatPhoneNumberWithCountryCode } from "utils/CountryCodes";
import RoundHistory from "components/Dashboard/RoundHistory/RoundHistory";

const InvestorDashboard = () => {
  const [deals, setDeals] = useState([]);
  const subscribedPromises = useRef([]);
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [filterDeals, setfilterDeals] = useState();
  const [sortedData, setSortedData] = useState([]);

  const [sortState, setSortState] = useState({ sortColumn: "" });
  const handleFilterChange = (event) => {
    setfilterDeals(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    const dealsPromise = get("/ventures");

    dealsPromise.promise
      .then((response) => {
        const syndicationGroupDeals = response.data.filter((data) => {
          if (!!data?.nominations?.length) {
            if (
              user?.group ||
              user?.syndicationGroup ||
              user?.member?.syndicationGroup
            ) {
              return (
                data?.nominations?.some(
                  (item) =>
                    item?.syndicationGroup?.id === user?.syndicationGroup?.id
                ) ||
                data?.nominations?.some(
                  (item) =>
                    item?.syndicationGroup?.id ===
                    user?.member?.syndicationGroup?.id
                )
              );
            } else {
              if (!user?.group || user.syndicationGroup)
                return data?.nominations?.some(
                  (item) => item?.syndicationGroup?.title === "Northeast"
                );
            }
          }
        });

        setDeals(user?.syndicationGroupVentures || syndicationGroupDeals);
        setSortedData(
          user?.syndicationGroupVentures?.sort((a, b) =>
            a.createdAt.localeCompare(b.createdAt)
          ) ||
            syndicationGroupDeals?.sort((a, b) =>
              a.createdAt.localeCompare(b.createdAt)
            )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(dealsPromise);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [user.syndicationGroupVentures]);

  const sortDeals = (selectedColumn) => {
    setSortState({ sortColumn: selectedColumn });
    deals.sort((a, b) => {
      switch (selectedColumn) {
        case "companyName":
          return a.companyName.localeCompare(b.companyName);
        case "_companyName":
          return b.companyName.localeCompare(a.companyName);
        case "updatedAt":
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        case "_updatedAt":
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        default:
          return a.createdAt.localeCompare(b.createdAt);
      }
    });
  };
  const filteredDeals = sortedData.filter((deal) =>
    deal?.companyName?.toLowerCase().includes(filterDeals?.toLowerCase())
  );

  const getDaysDifference = (updatedAt) =>
    Math.floor((new Date() - new Date(updatedAt)) / (1000 * 60 * 60 * 24));

  const getDayOrDays = (daysCount) => (daysCount <= 1 ? "day" : "days");

  const combinedDeals = filterDeals ? filteredDeals : sortedData;
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="my-5">
            <Card.Body>
              <div className="bg-white d-flex flex-column card-header">
                <Card.Body className="px-0">
                  <div className="d-flex float-md-end">
                    <h3 className="float-md-start float-lg-end ms-3">
                      <big>{combinedDeals?.length}</big> deals
                    </h3>
                  </div>
                  <div className="d-flex float-md-start float-lg-end my-2">
                    <Form.Select
                      aria-label="sorting-selector"
                      size="sm"
                      value={sortState.sortColumn || ""}
                      onChange={(ev) => sortDeals(ev.target.value)}
                    >
                      <option key={"All"} value={""}>
                        Select Sorting Order
                      </option>
                      {[
                        { attr: "companyName", label: "Company Name - A to Z" },
                        {
                          attr: "_companyName",
                          label: "Company Name - Z to A",
                        },
                        {
                          attr: "updatedAt",
                          label: "Updated Date - Newest to Oldest",
                        },
                        {
                          attr: "_updatedAt",
                          label: "Updated Date - Oldest to Newest",
                        },
                      ].map((filter) => (
                        <option key={filter.attr} value={filter.attr}>
                          {filter.label}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control
                      type="text"
                      size="sm"
                      value={filterDeals}
                      onChange={handleFilterChange}
                      placeholder="Filter by Company Name"
                      className="mx-2"
                    />
                  </div>
                  <h2 className="text-primary mb-1">
                    <span>
                      {user.group ? user?.group : user?.syndicationGroup?.title}{" "}
                      Deals
                    </span>
                  </h2>
                  <small className="text-muted">
                    Deals that have not been updated in over 60 days will be
                    removed from the list.
                  </small>
                </Card.Body>
              </div>

              {combinedDeals.length === 0 ? (
                <Alert className="m-auto" variant="info">
                  <h4 className="text-center"> No Deals Found </h4>
                </Alert>
              ) : (
                <Row className="justify-content-center">
                  <Col md={12} className="table-fs">
                    <div>
                      <Table className="desktop-table no-border-row" size="sm">
                        <thead className="bg-white sticky-header">
                          <tr>
                            <th className="p-3">Company</th>
                            <th className="p-3">Description</th>
                            <th className="p-3">
                              <div className="flex flex-row">
                                <div>Lead Group </div>
                                <div>Deal Lead Name</div>
                              </div>
                            </th>
                            <th className="p-3">
                              <div className="flex flex-row">
                                <div>Security Type</div>
                                <div>Raise at Valuation/Cap</div>
                                <div>% Raised</div>
                              </div>
                            </th>
                            <th className="p-3">
                              <div className="flex flex-row">
                                <div>Special Interest Qualifications</div>
                                <div>Syndication Group</div>
                              </div>
                            </th>

                            <th className="p-3">Links</th>
                            <th className="p-3">Details</th>
                          </tr>
                        </thead>

                        <tbody>
                          {combinedDeals.map((deal) => {
                            const daysDifference = getDaysDifference(
                              deal?.currentVentureRound?.updatedAt
                            );
                            const dayOrDays = getDayOrDays(daysDifference);
                            return (
                              <>
                                <tr key={deal.id}>
                                  <td className="pt-3 px-3 state-col border-0">
                                    <div className="d-flex flex-column">
                                      {
                                        <a
                                          // href={
                                          //   deal.companyWebsite &&
                                          //   deal.companyWebsite
                                          // }
                                          href={
                                            deal?.companyWebsite?.startsWith(
                                              "http"
                                            )
                                              ? deal?.companyWebsite
                                              : `https://${deal?.companyWebsite}`
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <strong>{deal.companyName}</strong>
                                        </a>
                                      }
                                      <div className="flex flex-column">
                                        <i className="bi bi-envelope-at"></i>{" "}
                                        <a
                                          href={`mailto:${deal.emailAddress}?cc=${deal?.dealLeadEmail}`}
                                        >
                                          {deal.ceoName}
                                        </a>
                                      </div>
                                      {deal?.mobileNumber && (
                                        <div className="flex flex-column">
                                          <i className="bi bi-phone"></i>{" "}
                                          {deal.mobileNumber &&
                                            `${formatPhoneNumberWithCountryCode(
                                              deal?.mobileNumber
                                            )}`}
                                        </div>
                                      )}
                                      {deal.state && (
                                        <>
                                          <div className="flex flex-column">
                                            <i className="bi bi-geo-alt"></i>{" "}
                                            {deal.state && `${deal.state}`}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>

                                  <td className="pt-3 px-3 border-0">
                                    {" "}
                                    <div>
                                      {deal.description.length > 155
                                        ? deal.description.slice(0, 155) + "..."
                                        : deal.description}
                                    </div>
                                  </td>
                                  <td className="pt-3 px-3 border-0">
                                    <div className="mb-2 d-flex justify-content-center flex-column">
                                      <div className="flex flex-wrap">
                                        {deal.dealLeadGroup}
                                      </div>
                                      <div className="flex flex-wrap">
                                        <i className="bi bi-envelope-at"></i>{" "}
                                        <a
                                          href={`mailto:${deal?.dealLeadEmail?.replace(
                                            "|",
                                            ""
                                          )}`}
                                        >
                                          {deal?.dealLeadName}
                                        </a>
                                      </div>
                                      {deal.dealRoomLink && (
                                        <div className="flex flex-wrap">
                                          <a
                                            target="_blank"
                                            href={`${deal.dealRoomLink}`}
                                            rel="noreferrer"
                                          >
                                            Deal Room
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className="pt-3 px-3 border-0">
                                    {Number(
                                      deal?.currentVentureRound?.isRoundClosed
                                    ) ? (
                                      <div className="flex flex-wrap my-2 fs-6">
                                        <Badge className="p-2 bg-dark">
                                          Round Closed
                                        </Badge>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="flex flex-wrap">
                                          {
                                            deal?.currentVentureRound
                                              ?.securityType
                                          }
                                        </div>
                                        <div className="flex flex-wrap text-wrap">
                                          {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            currencyDisplay: "narrowSymbol",
                                            maximumFractionDigits: 0,
                                          }).format(
                                            Number(
                                              deal?.currentVentureRound?.amount
                                            )
                                          )}
                                          <span className="mx-2">at</span>
                                          {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            currencyDisplay: "narrowSymbol",
                                            maximumFractionDigits: 0,
                                          }).format(
                                            Number(
                                              deal?.currentVentureRound
                                                ?.valuation
                                            )
                                          )}
                                        </div>
                                        <div className="flex flex-wrap">
                                          <span
                                            className={`badge p-2 table-fs text-wrap ${
                                              deal?.currentVentureRound
                                                ?.committed === "Starting (0%)"
                                                ? "bg-danger text-white"
                                                : deal?.currentVentureRound
                                                    ?.committed ===
                                                  "At least (50%)"
                                                ? "bg-warning text-white"
                                                : deal?.currentVentureRound
                                                    ?.committed ===
                                                  "At least (75%)"
                                                ? "bg-success text-white"
                                                : deal?.currentVentureRound
                                                    ?.committed ===
                                                  "Over subscribing (100%)"
                                                ? "bg-success text-white"
                                                : "bg-warning text-white"
                                            }`}
                                          >
                                            {
                                              deal?.currentVentureRound
                                                ?.committed
                                            }
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td className="pt-3 px-3 border-0">
                                    <div className="mb-2 d-flex flex-column">
                                      <div className="d-flex flex-wrap">
                                        {deal.qualification.length > 0 &&
                                          deal.qualification.map(
                                            (qualification, index) => (
                                              <span
                                                className="badge bg-secondary me-2 mb-2"
                                                key={index}
                                              >
                                                {qualification}
                                              </span>
                                            )
                                          )}
                                      </div>
                                      <div>
                                        {!!deal?.nominations?.length
                                          ? deal?.nominations?.map(
                                              (item, index) => (
                                                <span key={index}>
                                                  {
                                                    item?.syndicationGroup
                                                      ?.title
                                                  }
                                                  {index !==
                                                    deal?.nominations?.length -
                                                      1 && ", "}
                                                </span>
                                              )
                                            )
                                          : "Northeast"}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="pt-3 px-3 border-0">
                                    <div className="mb-2 d-flex justify-content-center flex-column">
                                      <div className="flex flex-wrap">
                                        {deal.slideDeckLink && (
                                          <a
                                            // href={deal.slideDeckLink}
                                            href={
                                              deal?.slideDeckLink?.startsWith(
                                                "http"
                                              )
                                                ? deal?.slideDeckLink
                                                : `https://${deal?.slideDeckLink}`
                                            }
                                            className="text-decoration-none"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Slides
                                          </a>
                                        )}
                                      </div>
                                      <div className="flex flex-wrap">
                                        {deal.pitchVideoLink && (
                                          <a
                                            // href={deal.pitchVideoLink}
                                            href={
                                              deal?.pitchVideoLink?.startsWith(
                                                "http"
                                              )
                                                ? deal?.pitchVideoLink
                                                : `https://${deal?.pitchVideoLink}`
                                            }
                                            className="text-decoration-none"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Video
                                          </a>
                                        )}
                                      </div>
                                      <div className="flex flex-wrap">
                                        {deal.executiveSummaryLink && (
                                          <a
                                            // href={deal.executiveSummaryLink}
                                            href={
                                              deal?.executiveSummaryLink?.startsWith(
                                                "http"
                                              )
                                                ? deal?.executiveSummaryLink
                                                : `https://${deal?.executiveSummaryLink}`
                                            }
                                            target="_blank"
                                            className="text-decoration-none"
                                            rel="noreferrer"
                                          >
                                            Ex.Summary
                                          </a>
                                        )}
                                      </div>
                                      <div className="flex flex-wrap">
                                        {deal.gustProfileLink && (
                                          <a
                                            // href={deal.gustProfileLink}
                                            href={
                                              deal?.gustProfileLink?.startsWith(
                                                "http"
                                              )
                                                ? deal?.gustProfileLink
                                                : `https://${deal?.gustProfileLink}`
                                            }
                                            target="_blank"
                                            className="text-decoration-none"
                                            rel="noreferrer"
                                          >
                                            Gust Profile
                                          </a>
                                        )}
                                      </div>
                                      <div className="flex flex-wrap">
                                        {deal.dealumProfileLink && (
                                          <a
                                            // href={deal.dealumProfileLink}
                                            href={
                                              deal?.dealumProfileLink?.startsWith(
                                                "http"
                                              )
                                                ? deal?.dealumProfileLink
                                                : `https://${deal?.dealumProfileLink}`
                                            }
                                            target="_blank"
                                            className="text-decoration-none"
                                            rel="noreferrer"
                                          >
                                            Dealum Profile
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="pt-3 px-3 border-0">
                                    <div className="mb-2 d-flex justify-content-center flex-column">
                                      {!!deal?.currentVentureRound
                                        ?.isPresenter && (
                                        <Badge className="px-3 py-2 bg-transparent border text-dark border-dark text-uppercase">
                                          Presenter
                                        </Badge>
                                      )}
                                    </div>
                                    <VoteCaster deal={deal} />
                                    <RoundHistory
                                      roundHistory={deal?.ventureRounds}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-3" colSpan="7">
                                    <strong>
                                      It has been {daysDifference} {dayOrDays}{" "}
                                      since last updated by entrepreneur.
                                    </strong>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                      {/* MOBILE  */}
                      <Table className="mobile-table" size="sm" responsive>
                        <thead className="bg-white">
                          <tr>
                            <th className="p-3">Company</th>
                            <th className="p-3">Description</th>
                            <th className="p-3">
                              <div className="flex flex-row">
                                <div>Lead Group </div>
                                <div>Deal Lead Name</div>
                              </div>
                            </th>
                            <th className="p-3">
                              <div className="flex flex-row">
                                <div>Security Type</div>
                                <div>Raise at Valuation/Cap</div>
                                <div>% Raised</div>
                              </div>
                            </th>
                            <th className="p-3">
                              <div className="flex flex-row">
                                <div>Special Interest Qualifications</div>
                                <div>Syndication Group</div>
                              </div>
                            </th>

                            <th className="p-3">Links</th>
                            <th className="p-3">Details</th>
                          </tr>
                        </thead>

                        <tbody>
                          {combinedDeals.map((deal) => {
                            const daysDifference = Math.floor(
                              (new Date() -
                                new Date(
                                  deal?.currentVentureRound?.updatedAt
                                )) /
                                (1000 * 60 * 60 * 24)
                            );
                            return (
                              <tr
                                key={deal.id}
                                style={{
                                  backgroundColor:
                                    daysDifference > 14
                                      ? "rgba(0, 0, 0, 0.05)"
                                      : "",
                                }}
                              >
                                <td className="p-3 state-col">
                                  <div className="d-flex flex-column">
                                    {
                                      <a
                                        // href={
                                        //   deal.companyWebsite &&
                                        //   deal.companyWebsite
                                        // }
                                        href={
                                          deal?.companyWebsite?.startsWith(
                                            "http"
                                          )
                                            ? deal?.companyWebsite
                                            : `https://${deal?.companyWebsite}`
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {deal.companyName}
                                      </a>
                                    }
                                    <div className="flex flex-column">
                                      <i className="bi bi-envelope-at"></i>{" "}
                                      <a href={`mailto:${deal.emailAddress}`}>
                                        {deal.ceoName}
                                      </a>
                                    </div>
                                    {deal?.mobileNumber && (
                                      <div className="flex flex-column">
                                        <i className="bi bi-phone"></i>{" "}
                                        {deal.mobileNumber &&
                                          `${formatPhoneNumberWithCountryCode(
                                            deal?.mobileNumber
                                          )}`}
                                      </div>
                                    )}
                                    {deal.state && (
                                      <>
                                        <div className="flex flex-column">
                                          <i className="bi bi-geo-alt"></i>{" "}
                                          {deal.state && `${deal.state}`}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </td>

                                <td className="p-3">
                                  {" "}
                                  <div>
                                    {deal.description.length > 155
                                      ? deal.description.slice(0, 155) + "..."
                                      : deal.description}
                                  </div>
                                </td>
                                <td className="p-3">
                                  <div className="mb-2 d-flex justify-content-center flex-column">
                                    <div className="flex flex-wrap">
                                      {deal.dealLeadGroup}
                                    </div>
                                    <div className="flex flex-wrap">
                                      <i className="bi bi-envelope-at"></i>{" "}
                                      <a
                                        href={`mailto:${deal?.dealLeadEmail?.replace(
                                          "|",
                                          ""
                                        )}`}
                                      >
                                        {deal?.dealLeadName}
                                      </a>
                                    </div>
                                    {deal.dealRoomLink && (
                                      <div className="flex flex-wrap">
                                        <a
                                          target="_blank"
                                          href={`${deal.dealRoomLink}`}
                                          rel="noreferrer"
                                        >
                                          Deal Room
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td className="p-3">
                                  {Number(
                                    deal?.currentVentureRound?.isRoundClosed
                                  ) ? (
                                    <div className="flex flex-wrap my-2 fs-6">
                                      <Badge className="p-2 bg-dark">
                                        Round Closed
                                      </Badge>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="flex flex-wrap">
                                        {
                                          deal?.currentVentureRound
                                            ?.securityType
                                        }
                                      </div>
                                      <div className="flex flex-wrap text-wrap">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                          currencyDisplay: "narrowSymbol",
                                          maximumFractionDigits: 0,
                                        }).format(
                                          Number(
                                            deal?.currentVentureRound?.amount
                                          )
                                        )}
                                        <span className="mx-2">at</span>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                          currencyDisplay: "narrowSymbol",
                                          maximumFractionDigits: 0,
                                        }).format(
                                          Number(
                                            deal?.currentVentureRound?.valuation
                                          )
                                        )}
                                      </div>
                                      <div className="flex flex-wrap">
                                        <span
                                          className={`badge p-2 table-fs text-wrap ${
                                            deal?.currentVentureRound
                                              ?.committed === "Starting (0%)"
                                              ? "bg-danger text-white"
                                              : deal?.currentVentureRound
                                                  ?.committed ===
                                                "At least (50%)"
                                              ? "bg-warning text-white"
                                              : deal?.currentVentureRound
                                                  ?.committed ===
                                                "At least (75%)"
                                              ? "bg-success text-white"
                                              : deal?.currentVentureRound
                                                  ?.committed ===
                                                "Over subscribing (100%)"
                                              ? "bg-success text-white"
                                              : "bg-warning text-white"
                                          }`}
                                        >
                                          {deal?.currentVentureRound?.committed}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td className="p-3">
                                  <div className="mb-2 d-flex flex-column">
                                    <div className="d-flex flex-wrap">
                                      {deal.qualification.length > 0 &&
                                        deal.qualification.map(
                                          (qualification, index) => (
                                            <span
                                              className="badge bg-secondary me-2 mb-2"
                                              key={index}
                                            >
                                              {qualification}
                                            </span>
                                          )
                                        )}
                                    </div>
                                    <div>
                                      {!!deal?.nominations?.length
                                        ? deal?.nominations?.map(
                                            (item, index) => (
                                              <span key={index}>
                                                {item?.syndicationGroup?.title}
                                                {index !==
                                                  deal?.nominations?.length -
                                                    1 && ", "}
                                              </span>
                                            )
                                          )
                                        : "Northeast"}
                                    </div>
                                  </div>
                                </td>
                                <td className="p-3">
                                  <div className="mb-2 d-flex justify-content-center flex-column">
                                    <div className="flex flex-wrap">
                                      {deal.slideDeckLink && (
                                        <a
                                          // href={deal.slideDeckLink}
                                          href={
                                            deal?.slideDeckLink?.startsWith(
                                              "http"
                                            )
                                              ? deal?.slideDeckLink
                                              : `https://${deal?.slideDeckLink}`
                                          }
                                          className="text-decoration-none fs-6"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Slides
                                        </a>
                                      )}
                                    </div>
                                    <div className="flex flex-wrap">
                                      {deal.pitchVideoLink && (
                                        <a
                                          // href={deal.pitchVideoLink}
                                          href={
                                            deal?.pitchVideoLink?.startsWith(
                                              "http"
                                            )
                                              ? deal?.pitchVideoLink
                                              : `https://${deal?.pitchVideoLink}`
                                          }
                                          className="text-decoration-none"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Video
                                        </a>
                                      )}
                                    </div>
                                    <div className="flex flex-wrap">
                                      {deal.executiveSummaryLink && (
                                        <a
                                          // href={deal.executiveSummaryLink}
                                          href={
                                            deal?.executiveSummaryLink?.startsWith(
                                              "http"
                                            )
                                              ? deal?.executiveSummaryLink
                                              : `https://${deal?.executiveSummaryLink}`
                                          }
                                          target="_blank"
                                          className="text-decoration-none"
                                          rel="noreferrer"
                                        >
                                          Ex.Summary
                                        </a>
                                      )}
                                    </div>
                                    <div className="flex flex-wrap">
                                      {deal.gustProfileLink && (
                                        <a
                                          // href={deal.gustProfileLink}
                                          href={
                                            deal?.gustProfileLink?.startsWith(
                                              "http"
                                            )
                                              ? deal?.gustProfileLink
                                              : `https://${deal?.gustProfileLink}`
                                          }
                                          target="_blank"
                                          className="text-decoration-none"
                                          rel="noreferrer"
                                        >
                                          Gust Profile
                                        </a>
                                      )}
                                    </div>
                                    <div className="flex flex-wrap">
                                      {deal.dealumProfileLink && (
                                        <a
                                          // href={deal.dealumProfileLink}
                                          href={
                                            deal?.dealumProfileLink?.startsWith(
                                              "http"
                                            )
                                              ? deal?.dealumProfileLink
                                              : `https://${deal?.dealumProfileLink}`
                                          }
                                          target="_blank"
                                          className="text-decoration-none"
                                          rel="noreferrer"
                                        >
                                          Dealum Profile
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td className="p-3">
                                  <div className="mb-2 d-flex justify-content-center flex-column">
                                    {!!deal?.currentVentureRound
                                      ?.isPresenter && (
                                      <Badge className="px-3 py-2 bg-transparent border text-dark border-dark text-uppercase">
                                        Presenter
                                      </Badge>
                                    )}
                                  </div>
                                  <VoteCaster deal={deal} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {/* MOBILE VIEW END */}
                      <span className="table-fs text-muted">
                        Note: You can use the browser’s tools to Zoom In/Zoom
                        Out
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InvestorDashboard;
